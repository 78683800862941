<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <base-material-card color="#174282">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Ateliers
            </div>
          </template>
          <v-card-text>
            <v-carousel hide-delimiters height="auto">
              <v-carousel-item
                v-for="i in 14"
                :key="i"
                :src="'../../../ateliers/'+i+'.jpg'"
              ></v-carousel-item>
            </v-carousel>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="sass">
.WACStatusBarContainer
  display: none!important
</style>
<script>
  export default {
    name: 'Ateliers',
  }
</script>
